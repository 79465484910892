<template>
  <div>
    <CIDataTable
      :vuex="vuex"
      :settings="settings"
      :route="route"
      model="products"
      @uploadFromCSV="uploadFromCSV"
    />
    <Modal
      :modalShow="isShowModal"
      :messageGeneral="modalMessage"
      :title="modalTitle"
      v-on:toggle-modal="isShowModal = false"
      :isSuccessButton="false"
    />
  </div>
</template>
<script>
  import { products } from '@/config/descriptions/products';
  import { productsTexts } from '@/config/pageTexts/products.json';
  import axios from 'axios';
  import Modal from "@/shared/UI/Modal.vue";

  export default {
    name: 'ProductsTable',
    components: { Modal },
    data() {
      return {
        vuex: {
          getter: {
            theme: 'theme/theme',
            data: 'products/products',
            fields: 'products/fields',
            sortableFields: 'products/sortableFields',
            pages: 'products/pages',
            loading: 'products/loading',
            resultCount: 'products/resultCount',
            totalRecords: 'products/totalRecords',
            originalFields: 'products/originalFields',
            searchableAllFields: 'products/searchableAllFields',
            success: 'products/success',
            message: 'products/message',
          },
          actions: {
            fields: 'products/handleFields',
            delete: 'products/handleDeleteProducts',
            reset: 'products/handleClearFilter',
            query: 'products/handleGetProductsByQuery',
          },
        },
        settings: {
          describe: { ...products },
          page: { ...productsTexts },
        },
        route: {
          create: 'CreateProduct',
          edit: 'EditProduct',
          preview: 'PreviewProduct',
        },
        isShowModal: false,
        modalMessage: '',
        modalTitle: '',
      };
    },
    methods: {
      async uploadFromCSV(file) {
        const token = localStorage.getItem('vue-token')
        const hostname = window.location.hostname;
        const isStagingDev = hostname.includes('127.0.0.1');
        const isStaging = hostname.split('.').includes('staging');

        const baseUrl = isStagingDev || isStaging
          ? `https://api.staging.ziqni.io`
          : `https://api.ziqni.com`;

        const path = `${baseUrl}/upload-products-csv-file`;

        const formData = new FormData()
        formData.append('productsFile', file, file.name)

        await axios.post(path, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        })
          .then(result => {
            if (result.data.errors.length) {
              this.modalTitle = this.settings.page.csvPopupErrorTitle;
              this.modalMessage = this.settings.page.csvPopupErrorMessage;
              this.isShowModal = true;
            }

            if (result.data.results && result.data.results.length) {
              if (result.data.results[0].result === 'Updated') {
                this.modalTitle = this.settings.page.csvPopupTitle;
                this.modalMessage = this.settings.page.csvRecordsUpdated + ' ' + result.data.meta.totalRecords;
              } else {
                this.modalTitle = this.settings.page.csvPopupTitle;
                this.modalMessage = this.settings.page.csvRecordsCreated + ' ' + result.data.meta.totalRecords;
              }
              this.isShowModal = true;
            }
          })
          .catch(error => console.error(error))
      },
    }
  };
</script>
